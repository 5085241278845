// @flow
import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";

const Container = (props) => {
  return (
    <div id="container">
      <div id="flex-container">
        <div className="title">
          <h1>James Wilson</h1>
        </div>
        <div className="contact-container">
          <div className="phone-number">
            <a href="tel:+12816687475">(281) 668-7475</a>
          </div>
          <div className="email">
            <a href="mailto:j@meswilson.com">j@meswilson.com</a>
          </div>
          <div className="website print-hide">
            <a href="https://github.com/drakeapps" className="website-link">
              github
            </a>
          </div>
          <div className="website print-show">jameswilson.work</div>
        </div>
        <SectionTitle>Experience</SectionTitle>
        <Position
          {...{
            title: (
              <span>
                Software Engineer &mdash;{" "}
                <a href="https://www.google.com">Google</a> &mdash; 2022-Present
                &mdash; Austin, TX / Remote
              </span>
            ),
            description: [
              "Full stack web developer working on legal compliance tooling. Mostly using Java, Dart, and Typescript. Protobufs for API definition.",
            ],
            projects: [
              {
                title: "Internal Application - 2022-2023",
                description: [
                  "Building backend microservices in Java backed by Spanner",
                  "Writing frontend and components in Dart using Angular",
                  "Integrating with internal tooling for database and event streaming",
                ],
              },
            ],
          }}
        />
        <Position
          {...{
            title: (
              <span>
                Senior Software Engineer, Lead (Manager) &mdash;{" "}
                <a href="https://flightaware.com" rel="nofollow">
                  FlightAware
                </a>{" "}
                &mdash; 2015-2022 &mdash; Houston, TX / Remote
              </span>
            ),
            description: [
              "Full stack web developer. Successfully launched multiple projects on time with very specific and unmoving timelines. Manager of FlightAware's second, fully remote, web team. Mentored and onboarded multiple new hires. Championed the adoption of new technologies to keep us modern and productive.",
              "Backend built with TCL, Python, Node. Frontend composed of React to legacy jQuery. Containerization and packaging with Docker, nix, and pip. Scripting in Python and Node. Orchestration with Kubernetes. Automation with GitHub Actions and Jenkins.",
            ],
            projects: [
              {
                title: "Web Next - Tech Lead - 2021-2022",
                description: [
                  "Worked to help modernize and containerize 16 year old webstack running on BSD",
                  "Containerized CI pipelines and automated the production build and release",
                  "Implemented kubernetes orchestration for fully scaleable and automated QA and test hosts",
                  "Kicked off creating UI component library and modern user tokenization service",
                ],
              },
              {
                title: "Aviator - Tech Lead - 2021",
                description: [
                  "Tech lead of team that was comprised of members of nearly every development team at FlightAware to build a product targeting General Aviation",
                  "Launched a web and iOS project simultaneously for the Oshkosh 2021 event. First simultaneous app and web launch and first time associating iOS purchases with FlightAware",
                  "Built an Apple receipt processor/account management in Python that interfaced with our TCL infrastructure that allowed us to share the library between in app purchase verification and Apple webhook events.",
                  "Introduced React Redux to our frontend, allowing seamless account upgrades and aircraft management",
                ],
              },
              {
                title: "Surface Visualization - Tech Lead - 2020",
                description: [
                  "WebSockets powered map for tracking aircraft on the ground. Provided very near real time locations for all aircraft on ground at airports",
                ],
                pageBreak: true,
              },
              {
                title: "Flight Page Improvements - Lead - 2019",
                description: [
                  "Lead project spanning across multiple development teams to add features and enhancement on the flight page, our most trafficked page",
                  "Added a schedule visualizer with the ability to edit times and destination",
                  "Created a system that allowed user inputted data from the website to be treated as first rate flight data. The system was later used in the Aviator project to allow updates to flights created by the Flight Intent product",
                ],
              },
            ],
          }}
        ></Position>
        <PageBreak />
        <Position
          {...{
            title: (
              <span>
                Field Engineer 3 &mdash;{" "}
                <a
                  href="https://www.slb.com/drilling/bottomhole-assemblies/directional-drilling/slider-automated-slide-drilling-system"
                  rel="nofollow"
                >
                  Schlumberger
                </a>{" "}
                &mdash; 2010-2015 &mdash; Houston, TX / North America Land
              </span>
            ),
            description: [
              "Field engineer for Slider Automated Direction Drilling equipment. Installed, maintained, and diagnosed issues related to software, hardware, and third parties.",
            ],
          }}
        ></Position>
        <Position
          {...{
            title: (
              <span>Drake Apps, LLC &mdash; Owner &mdash; 2010-Present</span>
            ),
            description: [
              "Personal company used as a place to experiment with different ideas and new technologies, while also contributing to open source software.",
            ],
            projects: [
              {
                title: <></>,
                description: [
                  "Event tracking website written in Gatsby/React backed by Strapi. Previously in Django",
                  "Reddit link tracking site with over 10M API requests per month running PHP with PostgreSQL on a lightweight VPS",
                  "Various other projects using Django, WordPress, or bespoke Python or PHP. Enhanced with React. Mostly backed with Postgres. Everything in Docker. Orchestration with k8s. All using GitHub Actions CI.",
                ],
              },
            ],
          }}
        ></Position>
        <SectionTitle>Education</SectionTitle>
        <Education
          {...{
            title: "University of Texas at San Antonio",
            degree:
              "Bachelor's of Science in Electrical Engineering. Minor in Mathematics",
            description: [
              "Focus on computer engineering. Writing software in Assembly, C, and Python. Building and designing hardware from chip design to PCB design and assembly to integrating with various sensors and motors.",
              // "Final project was a three-axis automated adjusting desk designed for users that had limited upper body control. It used linear actuators, various IR and ultrasonic sensors, and was powered by an Arduino.",
            ],
          }}
        ></Education>
        <SectionTitle>Skills</SectionTitle>
        <Skills
          skills={[
            "Python",
            "JavaScript",
            "React",
            "Django",
            "CSS",
            "PHP",
            "TCL",
            "Docker",
            "nix",
            "PostreSQL",
            "MySQL",
            "SQLite",
          ]}
        ></Skills>
      </div>
    </div>
  );
};

const SectionTitle = (props) => {
  return (
    <div className="section-title">
      <h2>{props.children}</h2>
    </div>
  );
};

const Position = (props) => {
  return (
    <>
      <div className="position-title">
        <h3>{props.title}</h3>
      </div>
      {props.description.map((item) => (
        <PositionDescription key={item} item={item}></PositionDescription>
      ))}
      {props.projects ? (
        <ProjectList projects={props.projects}></ProjectList>
      ) : null}
      {props.pageBreak ? <PageBreak /> : null}
    </>
  );
};

const PositionDescription = (props) => {
  return (
    <div className="position-description">
      <p>{props.item}</p>
    </div>
  );
};

const ProjectList = (props) => {
  return (
    <div className="projects-container">
      <div className="projects-title">Projects</div>
      {props.projects.map((item) => (
        <ProjectItem key={item.title} {...item}></ProjectItem>
      ))}
    </div>
  );
};

const ProjectItem = (props) => {
  return (
    <div className="project-item">
      <div className="project-title">{props.title}</div>
      <ul className="project-list">
        {props.description.map((item) => (
          <ProjectBullet key={item} item={item}></ProjectBullet>
        ))}
      </ul>
    </div>
  );
};

const ProjectBullet = (props) => {
  return (
    <li>
      <div>{props.item}</div>
    </li>
  );
};

const Education = (props) => {
  return (
    <div className="education-container">
      <div className="education-title">
        <h3>{props.title}</h3>
      </div>
      <div className="education-degree">{props.degree}</div>
      <div className="education-descrption">
        {props.description.map((item) => (
          <EducationDescrption item={item}></EducationDescrption>
        ))}
      </div>
    </div>
  );
};

const EducationDescrption = (props) => {
  return <p>{props.item}</p>;
};

const Skills = (props) => {
  return (
    <div className="skill-container">
      <ul className="skill-list">
        {props.skills.map((item) => (
          <li>{item}</li>
        ))}
      </ul>
    </div>
  );
};

const PageBreak = (props) => {
  return <div className="page-break"></div>;
};

ReactDOM.render(
  <Container></Container>,
  document.getElementById("react-container")
);
